<template>
  <r-row class="px-4">
    <r-col v-for="item in items" :key="item" :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }">
      <r-announcement isLoading title image />
    </r-col>
  </r-row>
</template>

<script>
export default {
  name: "ListLoading",

  props: {
    items: {
      type: Number,
      default: () => 12,
    }
  }
}
</script>
