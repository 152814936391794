<template>
  <observer @on-change="onChange">
    <r-announcement :image="getImages"
                    :title="data.title"
                    :sub-title="subTitle"
                    :is-loading="isLoading"
                    :size="size"
                    @click.native="$emit('goto')"
    >
      <template v-slot:imageContent v-if="data.label">
        <r-badge :title="$truncate(data.label, 12)" inversion color="amelie"  />
      </template>
    </r-announcement>
  </observer>
</template>

<script>
import Observer from 'vue-intersection-observer';

export default {
  name: "LazyAnnouncement",

  props: {
    data: {
      type: [Object, Array],
      default: () => [],
    },
    size: {
      type: String,
      default: () => 'rhaenise'
    },
    type: {
      type: String,
      default: () => ''
    }
  },

  computed: {
    subTitle() {
      if (this.type === 'news' && this.data?.created_at) {
        return this.$moment(this.data?.created_at * 1000).format('D MMMM YYYY');
      }

      if (this.type === 'guide') {
        return this.$t('views.agencies_and_guides.guide');
      }

      if (this.type === 'travel') {
        return this.$t('views.agencies_and_guides.travel');
      }

      return this.data.category?.title ? this.data.category?.title : '';
    },

    getImages() {
      if (this.type === 'guide' || this.type === 'travel') {
        return this.data.image ? this.data.image.replace('extra', 'small') : '';
      } else {
        return this.data.images.length > 0 ? this.data.images[0].replace('extra', 'small') : '';
      }
    }
  },

  data() {
    return{
      isLoading: true,
    }
  },

  components: {
    Observer,
  },

  methods: {
    onChange(entry, unobserve) {
      if (entry.isIntersecting) {
        unobserve();
      }

      this.isLoading = !entry.isIntersecting;
    }
  }
}
</script>
